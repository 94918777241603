import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import { toArray } from '@/lib/utils';

import type { Ref } from 'react';
import type { TypeScrolledGridProps } from './types';

/**
 * ScrolledGrid
 */
const ScrolledGridBase = (
  {
    className = '',
    numCol = 4,
    minWidthElement = 'var(--card-size-s-w)',
    withSteps = false,
    children,
    gap = '0px',
    paddingBottom,
  }: TypeScrolledGridProps,
  ref: Ref<HTMLDivElement>,
) => (
  <div className={`-mx-4 ${className}`}>
    <div
      className="scroll-px-4 overflow-x-auto overflow-y-hidden scrollbar scrollbar-track-primary-100 scrollbar-thumb-primary-400 scrollbar-corner-primary-400 scrollbar-track-rounded-none scrollbar-thumb-rounded-none scrollbar-h-1 [&::-webkit-scrollbar-thumb]:w-8"
      ref={ref}
    >
      <div
        className="grid gap-0 px-4 py-px"
        style={{
          gridTemplateColumns: `repeat(${numCol}, minmax(0, 1fr))`,
          minWidth: oneLine`
            calc(
              ${minWidthElement} * ${numCol} +
              ${numCol - 1} * ${gap ?? '1px'} +
              2rem
            )
          `,
          gap,
          paddingBottom,
        }}
      >
        {toArray(children).map((child, index) =>
          withSteps ? (
            <div
              className="relative flex flex-col [&:first-of-type>span]:before:bg-primary-50 [&:last-of-type>span]:after:right-0 [&:last-of-type>span]:after:bg-primary-900 md:[&:last-of-type>span]:after:bg-primary-50"
              key={`item-${index}`}
            >
              <span
                className="mb-6 flex h-10 w-10 items-center justify-center rounded-full bg-primary-900 text-typo-alternative before:absolute before:left-0 before:right-14 before:top-5 before:h-px before:-translate-y-1/2 before:bg-primary-900 before:content-none after:absolute after:left-14 after:right-4 after:top-5 after:h-px after:-translate-y-1/2 after:bg-primary-900 after:content-[''] md:mx-auto md:before:right-[calc(50%+36px)] md:before:content-[''] md:after:left-[calc(50%+36px)] md:after:right-0"
                aria-hidden="true"
              >
                {index + 1}
              </span>

              <div className="grow [&>*]:h-full">{child}</div>
            </div>
          ) : (
            child
          ),
        )}
      </div>
    </div>
  </div>
);

ScrolledGridBase.displayName = 'ScrolledGrid';

export const ScrolledGrid = forwardRef(ScrolledGridBase);
