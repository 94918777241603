import { NextLink, Text } from '@/atoms';
import { Button, CardStep, ScrolledGrid } from '@/molecules';

import { removeBaseURL } from '@/lib/utils';

/**
 * StepperWordpress
 */
export const StepperWordpress = ({ stepper }: any) => (
  <div className="flex flex-col justify-center">
    {(stepper.pretitulo || stepper.titulo) && (
      <div className="my-4 flex w-full flex-col items-center">
        {stepper.pretitulo && (
          <p className="u-actions--link mb-4 block text-secondary-500">
            {stepper.pretitulo}
          </p>
        )}

        {stepper.titulo && (
          <Text as={stepper.headingType} className="u-headline u-headline--h2">
            {stepper.titulo}
          </Text>
        )}
      </div>
    )}

    <ScrolledGrid
      withSteps={true}
      numCol={stepper.steps.length}
      minWidthElement="290px"
      className="[&+a]:mt-3 [&+a]:xs:mt-10"
    >
      {stepper.steps.map((step: any, index: number) => (
        <CardStep
          key={index}
          image={step?.imagen}
          pretitle={step.subtitulo}
          title={step.titulo}
          size={stepper.size}
          stroke={stepper.stroke}
          button={step?.button}
          description={step?.descripcion}
          headingType={step.headingType}
        />
      ))}
    </ScrolledGrid>

    {stepper?.contentAfterSteps && (
      <div
        className="u-label u-label--s mb-6 mt-3 flex w-full flex-col items-center justify-center text-center normal-case xs:mt-10 [&>:not(*:last-child)]:mb-2"
        dangerouslySetInnerHTML={{ __html: stepper.contentAfterSteps }}
      />
    )}

    {stepper?.label && stepper?.link && (
      <NextLink href={removeBaseURL(stepper?.link.url ?? '')} passHref>
        <Button
          as="a"
          variant={stepper?.style ?? 'primary'}
          className="mx-auto min-w-full md:min-w-fit"
        >
          {stepper.label}
        </Button>
      </NextLink>
    )}
  </div>
);

StepperWordpress.displayName = 'StepperWordpress';
