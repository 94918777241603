import { Container } from '@/atoms';
import { StepperWordpress } from '@/templates';

/**
 * Stepper
 */
const Stepper = ({ bloque, id, className }: any) => (
  <Container
    id={id}
    wrapper
    as="section"
    padding="px-4 py-8"
    backgroundColor={bloque.background ? `bg-${bloque.background}` : undefined}
    {...(className && { className })}
  >
    <StepperWordpress stepper={bloque} />
  </Container>
);

Stepper.displayName = 'FlexibleStepper';

export default Stepper;
